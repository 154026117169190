import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const PageAbout = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Nosotros' />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Estamos para ayudarte'
          subheader='Contamos con un equipo completo de periodistas, Contadores, Economistas y entendidos del tema, para poder acercarte las últimas noticias sobre el sector financiero. Estamos comprometidos a traerte la verdad y consejos financieros que realmente te ayuden en tu vida diaria.'
        />
      </Stack>

    </Layout>
  )
}

export default PageAbout
